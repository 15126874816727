import React from "react";
import axios from "axios";
import $ from "jquery";
import { ClipLoader } from "react-spinners";
import { Fragment } from "react";

import "./Calc.css";

const ROOT_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : "https://ezice.herokuapp.com";

const SIZE_MAP = {
  kiddie: { width: 15, length: 30, price: 2550, id: "kiddie", name: "Kiddie" },
  small: { width: 20, length: 40, price: 3280, id: "small", name: "Small" },
  medium: { width: 25, length: 50, price: 4030, id: "medium", name: "Medium" },
  large: { width: 30, length: 60, price: 4830, id: "large", name: "Large" },
  xl: { width: 40, length: 80, price: 6470, id: "xl", name: "XL" },
  xxl: { width: 60, length: 100, price: 8920, id: "xxl", name: "XXL" },
  parks_rec: {
    width: 80,
    length: 120,
    price: 11590,
    id: "parks_rec",
    name: "Parks & Rec",
  },
  nhl: { width: 85, length: 200, price: 18040, id: "nhl", name: "NHL Size" },
};

const PRESETS = [
  { id: "111", name: "Classic Height Rink" },
  { id: "212", name: "Classic Height + Double Ends" },
  { id: "114", name: "Classic Height + Arena Netting (One End)" },
  { id: "414", name: "Classic Height + Arena Netting (Both Ends)" },
  { id: "434", name: "Arena Height + Netting Ends" },
  { id: "444", name: "Arena Height + Netting Full" },
];

const STYLES_MAP = {
  classic: { id: 1, price: 100, name: "Classic Height" },
  double: { id: 2, price: 200, name: "Double Height" },
  arena: { id: 3, price: 300, name: "Arena Height" },
  arena_nets: { id: 4, price: 400, name: "Arena + Nets" },
};

const FEATURES_MAP = {
  rounded: { id: "rounded", price: 500, name: "Rounded Corners" },
  bumpers: { id: "bumpers", price: 600, name: "Foam Bumpers" },
};

class Calc extends React.Component {
  constructor(props) {
    super(props);

    const styles =
      (getQueryParam("style") && getQueryParam("style").split(",")) || [];

    this.state = {
      rink: getQueryParam("rink") || "medium",
      styleLeft: styles[0] || 1,
      styleMiddle: styles[1] || 1,
      styleRight: styles[2] || 1,
      features:
        (getQueryParam("features") && getQueryParam("features").split(",")) ||
        [],
      customWidth: getQueryParam("custom_width"),
      customLength: getQueryParam("custom_length"),
      isCustom: getQueryParam("custom_length") && getQueryParam("custom_width"),
      styleSide: "Left",
      imageUrl: "",
      basePrice: 1200,

      width: window.innerWidth,
      height: window.innerHeight,

      email: "",
      phone: "",

      showStraps: false,
      isModalOpen: false,
      isModalFeaturedStyles: false,
      isLaterTooltipActive: false,

      rinkSizeSelected: false,

      sentEmail: false,
      copiedToClipboard: false,

      renderCustomCalculator: false,

      priceStyle1: 0,
      priceStyle2: 0,
      priceStyle3: 0,
      priceStyle4: 0,
      priceFeature1a: 0,
      priceFeature1b: 0,
      priceFeature2a: 0,
      priceFeature2b: 0,

      presetPrice1: 0,
      presetPrice2: 0,
      presetPrice3: 0,
      presetPrice4: 0,
      presetPrice5: 0,
      presetPrice6: 0,

      scrollPoint: 0,
      calcRinkFixed: false,
      calcRinkRemoved: false,

      loading: false,
    };

    // this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.setDefaultParams();
    this.setImageUrl();

    this.calculateTotal();

    if (this.state.width <= 500) {
      const stickyImg = $(".rink-content-image");
      const boxInitialTop = stickyImg.offset().top;
      const bottomCalc = $(".rink-content-right");

      $(window).scroll(async () => {
        const topPoint = $(".rink-content-subtitle").offset().top;
        const bottomPoint = $(".contact-form-override").offset().top;
        const currentPoint = $(window).scrollTop();
        const isScrollingDown = currentPoint - this.state.scrollPoint > 0;
        await this.setState({ scrollPoint: currentPoint });

        const aboveTop = currentPoint < topPoint - 350;
        const belowTop = currentPoint > topPoint - 350;
        const aboveBottom = currentPoint < bottomPoint - 350;
        const belowBottom = currentPoint > bottomPoint - 350;

        if (isScrollingDown) {
          if (belowTop && aboveBottom && !this.state.calcRinkFixed) {
            stickyImg.addClass("rink-sticky-mobile");
            await this.setState({ calcRinkFixed: true });
          } else if (belowBottom) {
            stickyImg.removeClass("rink-sticky-mobile");
            await this.setState({ calcRinkRemoved: true });
          }
        }

        if (!isScrollingDown) {
          if (belowTop && aboveBottom && this.state.calcRinkFixed) {
            stickyImg.addClass("rink-sticky-mobile");
            await this.setState({ calcRinkFixed: false });
          } else if (aboveTop) {
            stickyImg.removeClass("rink-sticky-mobile");
            await this.setState({ calcRinkRemoved: false });
          }
        }
      });
    }

    // this.updateWindowDimensions();
    // window.addEventListener('resize', this.updateWindowDimensions);
    var _this = this;
    document.addEventListener(
      "click",
      function (event) {
        // If user either clicks X button OR clicks outside the modal window, then close modal by calling closeModal()
        if (
          !event.target.matches(".rink-selection-button-sublabel") &&
          !event.target.matches(".rink-selection-button") &&
          _this.state.isModalOpen &&
          !event.target.closest(".popup-content")
        ) {
          _this.setState({ isModalOpen: false });
          closeDialog();
        }
      },
      false
    );
  }

  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.updateWindowDimensions);
  // }

  // updateWindowDimensions() {
  //   this.setState({ width: window.innerWidth, height: window.innerHeight });
  // }

  setDefaultParams() {
    if (
      !getQueryParam("rink") &&
      !(getQueryParam("custom_length") && getQueryParam("custom_width"))
    )
      insertQueryParam("rink", "medium");
    if (!getQueryParam("style")) insertQueryParam("style", "4,1,1");
    if (!getQueryParam("features"))
      insertQueryParam("features", "rounded,rounded");
  }

  getWidth() {
    return (
      (this.state.isCustom && this.state.customWidth) || this.getRink().width
    );
  }
  getLength() {
    return (
      (this.state.isCustom && this.state.customLength) || this.getRink().length
    );
  }

  getRink(id) {
    if (id) {
      return SIZE_MAP[id];
    } else {
      return SIZE_MAP[this.state.rink];
    }
  }
  getStyle() {
    const style = this.state[`style${this.state.styleSide}`];

    return Object.values(STYLES_MAP)[style - 1];
  }

  generateSku(overrideObj = {}) {
    const width = (overrideObj.width || this.getWidth())
      .toString()
      .padStart(3, "0");
    const length = (overrideObj.length || this.getLength())
      .toString()
      .padStart(3, "0");
    const styleLeft = this.transposeStyle(
      overrideObj.styleLeft || this.state.styleLeft
    );
    const styleMiddle = this.transposeStyle(
      overrideObj.styleMiddle || this.state.styleMiddle
    );
    const styleRight = this.transposeStyle(
      overrideObj.styleRight || this.state.styleRight
    );
    const corners =
      overrideObj.corners ||
      (this.state.features.includes("rounded") ? "R" : "S");
    const bumpers =
      overrideObj.bumpers ||
      (this.state.features.includes("bumpers") ? "B" : "X");
    const extra = "X";

    return `${width}${length}${styleLeft}${styleMiddle}${styleRight}${corners}${bumpers}${extra}`;
  }

  humanizeSide(letter) {
    if (letter == "C") {
      return "Classic";
    } else if (letter == "D") {
      return "Double";
    } else if (letter == "A") {
      return "Arena";
    } else if (letter == "N") {
      return "Net";
    }
  }

  humanizeSides(left, middle, right) {
    return `${this.humanizeSide(left)}-${this.humanizeSide(
      middle
    )}-${this.humanizeSide(right)}`;
  }

  humanizedRink(overrideObj = {}) {
    const width = overrideObj.width || this.getWidth();
    const length = overrideObj.length || this.getLength();
    const styleLeft = this.transposeStyle(
      overrideObj.styleLeft || this.state.styleLeft
    );
    const styleMiddle = this.transposeStyle(
      overrideObj.styleMiddle || this.state.styleMiddle
    );
    const styleRight = this.transposeStyle(
      overrideObj.styleRight || this.state.styleRight
    );
    const corners =
      overrideObj.corners ||
      (this.state.features.includes("rounded") ? "R" : "S");
    const bumpers =
      overrideObj.bumpers ||
      (this.state.features.includes("bumpers") ? "B" : "X");

    return `EZ ICE PRO Home Arena System ™ – New Rink: [PRO // ${width}ft * ${length}ft // ${this.humanizeSides(
      styleLeft,
      styleMiddle,
      styleRight
    )} // ${corners == "S" ? "Square" : "Round"} Corners // ${
      bumpers == "B" ? "With" : "No"
    } Bumpers]`;
  }

  transposeStyle(styleNum) {
    if (styleNum == 1) {
      return "C";
    } else if (styleNum == 2) {
      return "D";
    } else if (styleNum == 3) {
      return "A";
    } else {
      return "N";
    }
  }

  handleCalculatePrice = async (event) => {
    const width = parseInt(event.target.value);

    this.setState({ customWidth: width });
  };

  handleLengthChange = (event) => {
    const length = parseInt(event.target.value);

    this.setState({ customLength: length });
  };
  handleWidthChange = (event) => {
    const width = parseInt(event.target.value);

    this.setState({ customWidth: width });
  };

  setImageUrl() {
    const img = this.getImageOrCustomImageUrl();
    this.setState({ imageUrl: img });

    $(".rink-content-image").attr("src", img);
  }

  convertCustomToRinkSize(width) {
    if (width >= 10 && width <= 15) {
      return { width: 15, length: 30 };
    } else if (width > 15 && width <= 20) {
      return { width: 20, length: 40 };
    } else if (width > 20 && width <= 25) {
      return { width: 25, length: 50 };
    } else if (width > 25 && width <= 35) {
      return { width: 30, length: 60 };
    } else if (width > 35 && width <= 50) {
      return { width: 40, length: 80 };
    } else if (width > 50 && width <= 70) {
      return { width: 60, length: 100 };
    } else if (width > 70 && width <= 80) {
      return { width: 80, length: 120 };
    } else if (width > 80) {
      return { width: 85, length: 200 };
    }
  }

  getImageOrCustomImageUrl() {
    if (this.state.isCustom) {
      const convertedObj = this.convertCustomToRinkSize(this.state.customWidth);

      this.setState({
        customShownWidth: convertedObj.width,
        customShownHeight: convertedObj.length,
      });

      return this.getImageUrl(convertedObj.width, convertedObj.length);
    } else {
      return this.getImageUrl();
    }
  }

  getImageUrl(
    width = this.getWidth(),
    length = this.getLength(),
    showStraps = this.state.showStraps,
    styleString = `${this.state.styleLeft}${this.state.styleMiddle}${this.state.styleRight}`
  ) {
    const rinkSize = `${width}x${length}`;
    const corners = this.state.features.includes("rounded") ? "R" : "S";
    const bumpers = this.state.features.includes("bumpers") ? "Y" : "N";
    const ice = showStraps ? "N" : "Y";

    let style;
    if (styleString == "111") {
      style = "01";
    } else if (styleString == "222") {
      style = "02";
    } else if (styleString == "333") {
      style = "03";
    } else if (styleString == "444") {
      style = "04";
    } else if (styleString == "414") {
      style = "05";
    } else if (styleString == "313") {
      style = "06";
    } else if (styleString == "212") {
      style = "07";
    } else if (styleString == "323") {
      style = "08";
    } else if (styleString == "424") {
      style = "09";
    } else if (styleString == "114") {
      style = "10";
    } else if (styleString == "113") {
      style = "11";
    } else if (styleString == "112") {
      style = "12";
    } else if (styleString == "223") {
      style = "13";
    } else if (styleString == "224") {
      style = "14";
    } else if (styleString == "411") {
      style = "15";
    } else if (styleString == "311") {
      style = "16";
    } else if (styleString == "211") {
      style = "17";
    } else if (styleString == "322") {
      style = "18";
    } else if (styleString == "422") {
      style = "19";
    } else if (styleString == "434") {
      style = "20";
    } else if (styleString == "334") {
      style = "21";
    } else if (styleString == "433") {
      style = "22";
    } else if (styleString == "213") {
      style = "23";
    } else if (styleString == "214") {
      style = "24";
    } else if (styleString == "314") {
      style = "25";
    } else if (styleString == "312") {
      style = "26";
    } else if (styleString == "412") {
      style = "27";
    } else if (styleString == "413") {
      style = "28";
    } else if (styleString == "423") {
      style = "29";
    } else if (styleString == "324") {
      style = "30";
    }

    return `https://ezice.s3.amazonaws.com/rink-calc/imgc/${rinkSize}_${corners}_${bumpers}_${ice}_${style}.jpg`;
  }

  toggleSidebar = () => {
    if ($(".header").css("display") == "none") {
      $(".header").css("display", "block");
    } else {
      $(".header").css("display", "none");
    }
  };

  saveCustom = async () => {
    await this.setState({ isCustom: true, rinkSizeSelected: true });

    // Save default
    if (!this.state.customWidth) {
      await this.setState({ customWidth: 10 });
    }
    if (!this.state.customLength) {
      await this.setState({ customLength: 10 });
    }

    if (this.state.customWidth > this.state.customLength) {
      await this.setState({
        customWidth: this.state.customLength,
        customLength: this.state.customWidth,
      });
    }

    insertQueryParam("custom_width", this.state.customWidth);
    insertQueryParam("custom_length", this.state.customLength);
    removeQueryParam("rink");

    this.calculateTotal();
    this.setImageUrl();
    this.toggleModal();
  };

  toggleTooltip = () => {
    this.setState({ isLaterTooltipActive: !this.state.isLaterTooltipActive });
  };

  toggleModal = async (modalType) => {
    if (modalType == "featured") {
      await this.fetchFeaturedPrices();
      await this.setState({
        isModalOpen: !this.state.isModalOpen,
        isModalFeaturedStyles: true,
      });
    } else {
      await this.setState({
        isModalOpen: !this.state.isModalOpen,
        isModalFeaturedStyles: false,
      });
    }

    if (this.state.isModalOpen) {
      showDialog();
    } else {
      closeDialog();
    }
  };

  toggleRink = async (rink) => {
    await this.setState({
      rink: rink.id,
      isCustom: false,
      basePrice: this.getRink(rink.id).price,
      rinkSizeSelected: true,
    });

    insertQueryParam("rink", rink.id);
    removeQueryParam("custom_length");
    removeQueryParam("custom_width");

    this.calculateTotal();
    this.setImageUrl();
    this.toggleModal();
  };
  toggleStyle = async (styleId) => {
    await this.setState({ [`style${this.state.styleSide}`]: styleId });

    // After increasing middle, bring up sides if middle is highest.
    if (this.state.styleSide == "Middle") {
      if (this.state.styleLeft < this.state.styleMiddle) {
        await this.setState({ styleLeft: this.state.styleMiddle });
      }
      if (this.state.styleRight < this.state.styleMiddle) {
        await this.setState({ styleRight: this.state.styleMiddle });
      }
      // After decreasing side, bring down middle if middle is highest.
    } else {
      if (
        this.state.styleMiddle > this.state.styleLeft ||
        this.state.styleMiddle > this.state.styleRight
      ) {
        await this.setState({
          styleMiddle: Math.min(this.state.styleLeft, this.state.styleRight),
        });
      }
    }

    insertQueryParam("style", this.generateStyleParam());

    this.calculateTotal();
    this.setImageUrl();
  };

  togglePreset = async (preset) => {
    await this.setState({
      styleLeft: preset.id[0],
      styleMiddle: preset.id[1],
      styleRight: preset.id[2],
    });

    insertQueryParam("style", this.generateStyleParam());

    this.calculateTotal();
    this.setImageUrl();
    this.toggleModal();
  };

  generateStyleParam() {
    return `${this.state.styleLeft || ""},${this.state.styleMiddle || ""},${
      this.state.styleRight || ""
    }`;
  }

  toggleStyleSide = async (styleSide) => {
    await this.setState({ styleSide });

    const styleIndex = this.getStyle().id - 1;
    $($(".rink-selection-style-radio")[styleIndex]).prop("checked", true);
    this.calculateTotal();
  };
  toggleFeature = (selectedFeatureId, e) => {
    const tar = $(e.target);
    let isChecked = e.target.checked;

    if (isChecked === undefined) {
      isChecked = !(
        tar.find(".rink-selection-style-radio")[0] ||
        tar.prev().prev()[0] ||
        tar.prev()[0]
      ).checked;
    }

    const features = this.state.features;

    if (isChecked) {
      features.push(selectedFeatureId);
    } else {
      const idx = features.findIndex(
        (featureId) => featureId === selectedFeatureId
      );
      features.splice(idx, 1);
    }

    this.setState({ features });
    insertQueryParam("features", features.join(","));
    this.setImageUrl();

    this.calculateTotal();
  };
  onStyleHover = (dir) => {
    this.setState({ styleHover: dir });
  };
  onStyleHoverOut = (e) => {
    this.setState({ styleHover: null });
  };

  calculateSidePrice(currentSide, height) {
    if (currentSide == "Left") {
      if (this.state.styleMiddle > Math.min(height, this.state.styleRight)) {
        return {
          styleLeft: height,
          styleMiddle: Math.min(height, this.state.styleRight),
        };
      } else {
        return { styleLeft: height };
      }
    } else if (currentSide == "Right") {
      if (this.state.styleMiddle > Math.min(height, this.state.styleLeft)) {
        return {
          styleRight: height,
          styleMiddle: Math.min(height, this.state.styleLeft),
        };
      } else {
        return { styleRight: height };
      }
    } else {
      if (height > Math.min(this.state.styleLeft, this.state.styleRight)) {
        return {
          styleMiddle: height,
          styleLeft: Math.max(height, this.state.styleLeft),
          styleRight: Math.max(height, this.state.styleRight),
        };
      } else {
        return { styleMiddle: height };
      }
    }
  }

  calculateTotal = () => {
    Promise.all([
      axios.get(`${ROOT_URL}/api/components/` + this.generateSku()),
      axios.get(
        `${ROOT_URL}/api/components/` +
          this.generateSku({
            styleLeft: 1,
            styleMiddle: 1,
            styleRight: 1,
            corners: "S",
            bumpers: "X",
          })
      ),
      axios.get(
        `${ROOT_URL}/api/components/` +
          this.generateSku(this.calculateSidePrice(this.state.styleSide, 1))
      ),
      axios.get(
        `${ROOT_URL}/api/components/` +
          this.generateSku(this.calculateSidePrice(this.state.styleSide, 2))
      ),
      axios.get(
        `${ROOT_URL}/api/components/` +
          this.generateSku(this.calculateSidePrice(this.state.styleSide, 3))
      ),
      axios.get(
        `${ROOT_URL}/api/components/` +
          this.generateSku(this.calculateSidePrice(this.state.styleSide, 4))
      ),
      axios.get(
        `${ROOT_URL}/api/components/` + this.generateSku({ corners: "R" })
      ),
      axios.get(
        `${ROOT_URL}/api/components/` + this.generateSku({ corners: "S" })
      ),
      axios.get(
        `${ROOT_URL}/api/components/` + this.generateSku({ bumpers: "B" })
      ),
      axios.get(
        `${ROOT_URL}/api/components/` + this.generateSku({ bumpers: "X" })
      ),
    ]).then((responses) => {
      this.setState({
        price: responses[0].data?.price || 0,
        basePrice: responses[1].data?.price || 0,
        priceStyle1: responses[2].data?.price || 0,
        priceStyle2: responses[3].data?.price || 0,
        priceStyle3: responses[4].data?.price || 0,
        priceStyle4: responses[5].data?.price || 0,
        priceFeature1a: responses[6].data?.price || 0,
        priceFeature1b: responses[7].data?.price || 0,
        priceFeature2a: responses[8].data?.price || 0,
        priceFeature2b: responses[9].data?.price || 0,
      });
    });
  };

  getNearestWidth() {
    return this.state.isCustom
      ? this.convertCustomToRinkSize(this.getWidth()).width
      : this.getWidth();
  }

  getNearestLength() {
    return this.state.isCustom
      ? this.convertCustomToRinkSize(this.getWidth()).length
      : this.getLength();
  }

  fetchFeaturedPrices = async () => {
    return Promise.all(
      PRESETS.map((preset) =>
        axios.get(
          `${ROOT_URL}/api/components/` +
            this.generateSku({
              width: this.getNearestWidth(),
              length: this.getNearestLength(),
              styleLeft: preset.id[0],
              styleMiddle: preset.id[1],
              styleRight: preset.id[2],
            })
        )
      )
    ).then(async (responses) => {
      await this.setState({
        presetPrice1: responses[0].data?.price || 0,
        presetPrice2: responses[1].data?.price || 0,
        presetPrice3: responses[2].data?.price || 0,
        presetPrice4: responses[3].data?.price || 0,
        presetPrice5: responses[4].data?.price || 0,
        presetPrice6: responses[5].data?.price || 0,
      });
    });
  };

  addToCart = async () => {
    // Ensure image is showing ice, not straps
    const imageUrlArr = this.getImageOrCustomImageUrl().split("_");
    imageUrlArr[3] = "Y";
    const imageUrl = imageUrlArr.join("_");

    const resp = await axios.post(`${ROOT_URL}/api/shopify/variant`, {
      sku: this.generateSku(),
      image_url: imageUrl,
      description: this.humanizedRink(),
    });

    await axios.post("/cart/add.js", {
      items: [
        {
          quantity: 1,
          id: resp.data.shopify_variant_id,
        },
      ],
    });

    window.location = "/cart";
  };

  sendDraftOrder = async () => {
    this.setState({ sentEmail: true });

    let clipboardString = window.location.href;
    if (!clipboardString.endsWith("#shoprinks"))
      clipboardString += "#shoprinks";
    const sku = this.generateSku();

    const resp = await axios.post(`${ROOT_URL}/api/shopify/draft_order`, {
      email: this.state.email,
      rink: sku,
    });
  };

  copyToClipboard = () => {
    this.setState({ copiedToClipboard: true });

    let clipboardString = window.location.href;
    if (!clipboardString.endsWith("#shoprinks"))
      clipboardString += "#shoprinks";

    // clipboardString += `&upgrade=${}`

    navigator.clipboard.writeText(clipboardString);
  };

  scrollIntoView = (step) => {
    if (step == "step_1") {
      $("#content").animate({ scrollTop: 0 }, "smooth");
    } else if (step == "step_2") {
      $("#content").animate(
        { scrollTop: $("#step_2").position().top - 100 },
        "smooth"
      );
    } else {
      $("#content").animate(
        { scrollTop: $("#content").height() + 5000 },
        "smooth"
      );
    }
  };

  toggleStraps = async () => {
    await this.setState({ showStraps: !this.state.showStraps });

    this.setImageUrl();
  };

  // TODO: get board segment sizes
  renderCalc = async () => {
    await this.setState({ renderCustomCalculator: true });

    let canvas = null;
    while (!canvas) {
      canvas = document.querySelector("canvas");
    }

    const ctx = canvas.getContext("2d");
    const boardSpacing = 1; // 1px gap

    let rects = [];
    let i = 0;
    let x = 0;
    let y = 0;
    let r;

    const rinkWidth = 20;
    const rinkHeight = 20;

    var sizeX = $("canvas").width();
    var sizeY = $("canvas").height();

    const pixelWidthSegments = sizeX / rinkWidth;
    const pixelHeightSegments = sizeY / rinkHeight;

    // Left wall
    while (y < sizeY) {
      rects.push({
        x: 10,
        y: y,
        w: 30,
        h: pixelHeightSegments - boardSpacing,
      });

      y += pixelHeightSegments;
    }

    y = 0;

    // Right wall
    while (y < sizeY) {
      rects.push({
        x: sizeX - 50,
        y: y,
        w: 30,
        h: pixelHeightSegments - boardSpacing,
      });

      y += pixelHeightSegments;
    }

    // Top wall
    while (x < sizeX) {
      rects.push({
        x: x,
        y: 10,
        w: pixelWidthSegments - boardSpacing,
        h: 30,
      });

      x += pixelWidthSegments;
    }

    x = 0;

    // Bottom wall
    while (x < sizeX) {
      rects.push({
        x: x,
        y: sizeY - 50,
        w: pixelWidthSegments - boardSpacing,
        h: 30,
      });

      x += pixelWidthSegments;
    }

    var scale = window.devicePixelRatio;
    canvas.width = Math.floor(sizeX * scale);
    canvas.height = Math.floor(sizeY * scale);

    // Normalize coordinate system to use css pixels.
    ctx.scale(scale, scale);

    // render initial rects.
    while ((r = rects[i++])) ctx.rect(r.x, r.y, r.w, r.h);
    ctx.fillStyle = "blue";
    ctx.fill();

    canvas.onmousemove = function (e) {
      var rect = this.getBoundingClientRect(),
        x = (e.clientX - rect.left) * scale,
        y = (e.clientY - rect.top) * scale,
        i = 0,
        r;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      while ((r = rects[i++])) {
        // add a single rect to path:
        ctx.beginPath();
        ctx.rect(r.x, r.y, r.w, r.h);

        // check if we hover it, fill red, if not fill it blue
        ctx.fillStyle = ctx.isPointInPath(x, y) ? "red" : "blue";

        ctx.fill();
      }
    };
  };

  render() {
    return (
      <section id="shoprinks">
        <div className="rink-container">
          <div className="rink-content">
            <div className="rink-content-left">
              <div className="rink-content-title-container">
                <div className="rink-content-title-container-left">
                  <img
                    className="rink-content-logo"
                    src="https://ezice.s3.amazonaws.com/rink-calc/imgc/logo.png"
                  />

                  <div className="rink-content-title-container-pro">
                    PRO
                    <span className="rink-content-title-container-tm">TM</span>
                  </div>
                  <div className="rink-content-title-container-home">
                    HOME ARENA SYSTEM
                  </div>
                  {this.state.rinkSizeSelected && (
                    <div>
                      <div className="rink-content-title-container-size">
                        {this.state.isCustom
                          ? "CUSTOM"
                          : `${this.getWidth()}x${this.getLength()}`}
                      </div>
                    </div>
                  )}
                </div>
                <div className="rink-content-title-container-right">
                  {this.props.upgradePrice ? (
                    <Fragment>
                      <div className="font-bold text-lg">
                        {this.props.upgradeRinkName}
                      </div>
                      <div className="font-bold text-3xl mt-2">
                        ${this.props.upgradePrice.toLocaleString()}
                      </div>
                      <button
                        className="hover:bg-ezice-blue-800 p-2 w-32 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                        onClick={() =>
                          !this.props.loading && this.props.addToCart()
                        }
                      >
                        {this.props.loading ? (
                          <ClipLoader color="#ffffff" size={25} />
                        ) : (
                          "Add to Cart"
                        )}
                      </button>

                      <div className="italic mt-2">
                        If all looks good, you can Add to Cart. Or, make changes
                        and select "Update" to see your new quote.
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="rink-content-title-container-blurb-title">
                        Now let's build your upgrade!
                      </div>
                      <div className="rink-content-title-container-blurb-title">
                        Design your new rink here.
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
              <div className="rink-content-image-container">
                <img
                  className="rink-content-image"
                  src={this.state.imageUrl}
                  alt=""
                  onClick={this.toggleStraps}
                />
              </div>
              {/* <div className="rink-content-subtitle">
                [ CLICK IMAGE TO REVEAL OUR PROPRIETARY STRAP SYSTEM ]
              </div> */}
              {this.state.isCustom && (
                <div className="rink-content-title-container-blurb-content">
                  ({this.state.customShownWidth}x{this.state.customShownHeight}{" "}
                  shown)
                </div>
              )}
            </div>
            <div className="rink-content-right">
              <div className="rink-step">
                <div
                  className="rink-step-number rink-step-one"
                  onClick={this.toggleModal}
                >
                  1
                </div>
                <button
                  className="rink-selection-button"
                  onClick={this.toggleModal}
                >
                  <div className="rink-selection-button-sublabel">Click to</div>
                  {"Select Rink Size" +
                    (this.state.rinkSizeSelected
                      ? `: ${this.getWidth()}x${this.getLength()}`
                      : "")}
                </button>
              </div>

              <div className="rink-step mb-4">
                <div
                  className="rink-step-number rink-step-two"
                  onClick={() => this.toggleModal("featured")}
                >
                  2
                </div>
                <button
                  className="rink-selection-button rink-selection-button-two"
                  onClick={() => this.toggleModal("featured")}
                >
                  <div className="rink-selection-button-sublabel">Click to</div>
                  Choose Rink Style
                </button>
              </div>

              <div className="rink-selection-style">
                <div className="rink-selection-style-content">
                  <div className="rink-selection-style-rink">
                    <div
                      className="rink-selection-style-rink-container"
                      onClick={() => this.toggleStyleSide("Left")}
                      onMouseEnter={(e) => this.onStyleHover("Left")}
                      onMouseLeave={(e) => this.onStyleHoverOut("Left")}
                    >
                      <img
                        className="rink-selection-style-rink-img"
                        src="https://ezice.s3.amazonaws.com/rink-calc/imgc/left_rink.png"
                        alt=""
                      />
                      <div
                        className={
                          "rink-selection-style-rink-label" +
                          (this.state.styleSide === "Left"
                            ? " rink-selection-style-rink-label-active"
                            : "") +
                          (this.state.styleHover === "Left" &&
                          this.state.styleSide != "Left"
                            ? " rink-selection-style-rink-label-hover"
                            : "")
                        }
                      >
                        LEFT
                      </div>
                    </div>
                    <div
                      className="rink-selection-style-rink-container"
                      onClick={() => this.toggleStyleSide("Middle")}
                      onMouseEnter={(e) => this.onStyleHover("Middle")}
                      onMouseLeave={(e) => this.onStyleHoverOut("Middle")}
                    >
                      <img
                        className="rink-selection-style-rink-img"
                        src="https://ezice.s3.amazonaws.com/rink-calc/imgc/middle_rink.png"
                        alt=""
                      />
                      <div
                        className={
                          "rink-selection-style-rink-label" +
                          (this.state.styleSide === "Middle"
                            ? " rink-selection-style-rink-label-active"
                            : "") +
                          (this.state.styleHover === "Middle" &&
                          this.state.styleSide != "Middle"
                            ? " rink-selection-style-rink-label-hover"
                            : "")
                        }
                      >
                        MID
                      </div>
                    </div>
                    <div
                      className="rink-selection-style-rink-container"
                      onClick={() => this.toggleStyleSide("Right")}
                      onMouseEnter={(e) => this.onStyleHover("Right")}
                      onMouseLeave={(e) => this.onStyleHoverOut("Right")}
                    >
                      <img
                        className="rink-selection-style-rink-img"
                        src="https://ezice.s3.amazonaws.com/rink-calc/imgc/right_rink.png"
                        alt=""
                      />
                      <div
                        className={
                          "rink-selection-style-rink-label" +
                          (this.state.styleSide === "Right"
                            ? " rink-selection-style-rink-label-active"
                            : "") +
                          (this.state.styleHover === "Right" &&
                          this.state.styleSide != "Right"
                            ? " rink-selection-style-rink-label-hover"
                            : "")
                        }
                      >
                        RIGHT
                      </div>
                    </div>
                  </div>

                  {Object.values(STYLES_MAP).map((styleObj) => {
                    return (
                      <div
                        className="rink-selection-choice pointer ml-16"
                        onClick={() => this.toggleStyle(styleObj.id)}
                      >
                        <input
                          type="radio"
                          className="rink-selection-style-radio"
                          value={styleObj.id}
                          name="style"
                          onClick={() => this.toggleStyle(styleObj.id)}
                          checked={this.getStyle().id === styleObj.id}
                        />
                        <label className="pointer mb-0">{styleObj.name}</label>
                        {/* <label className="rink-selection-price pointer">
                          [{" "}
                          {this.state[`priceStyle${styleObj.id}`] -
                            this.state.price >=
                          0
                            ? "+"
                            : "-"}
                          $
                          {Math.abs(
                            this.state[`priceStyle${styleObj.id}`] -
                              this.state.price
                          )}{" "}
                          ]
                        </label> */}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="rink-selection-style">
                <div className="rink-step rink-step-title">
                  <div className="rink-step-number rink-step-three">3</div>
                  <div className="rink-selection-style-title rink-selection-features-title">
                    Choose Rink Features
                  </div>
                </div>
                <div className="rink-selection-style-content rink-selection-features-content">
                  {Object.values(FEATURES_MAP).map((featureObj, idx) => {
                    return (
                      <div
                        className="rink-selection-choice pointer ml-12"
                        onClick={(e) => this.toggleFeature(featureObj.id, e)}
                      >
                        <input
                          type="checkbox"
                          className="rink-selection-style-radio"
                          value={featureObj.id}
                          onClick={(e) => this.toggleFeature(featureObj.id, e)}
                          checked={
                            !!this.state.features
                              .map((featureId) => FEATURES_MAP[featureId])
                              .find((feature) => feature.id === featureObj.id)
                          }
                        />
                        <label className="pointer mb-0">
                          {featureObj.name}
                        </label>
                        {/* <label className="rink-selection-price pointer">
                          [ $
                          {this.state[`priceFeature${idx + 1}a`] -
                            this.state[`priceFeature${idx + 1}b`]}{" "}
                          ]
                        </label> */}
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* <button className="rink-selection-button rink-selection-button-alt">Detailed Parts List</button> */}

              <div className="rink-footer-content">
                <div className="rink-footer-price">
                  {this.props.loading && (
                    <div className="text-sm w-60 text-center mb-2">
                      This may take up to 30 seconds, please don't leave the
                      page.
                    </div>
                  )}
                  <div className="rink-footer-buttons mt-1">
                    {this.props.upgradePrice ? (
                      <div className="flex">
                        <button
                          className="hover:bg-ezice-blue-800 py-2 px-4 text-xl font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                          onClick={() =>
                            !this.props.loading &&
                            this.props.calculateUpgradePrice({
                              styleLeft: this.state.styleLeft,
                              styleMiddle: this.state.styleMiddle,
                              styleRight: this.state.styleRight,
                              rink: this.state.rink,
                              features: this.state.features,
                              customWidth: this.state.customWidth,
                              customLength: this.state.customLength,
                            })
                          }
                        >
                          {this.props.loading ? (
                            <ClipLoader color="#ffffff" size={25} />
                          ) : (
                            "Update"
                          )}
                        </button>
                        <button
                          className="hover:bg-ezice-blue-800 text-sm py-2 px-4 ml-4 font-bold text-white bg-ezice-blue-100 rounded-full flex justify-center items-center"
                          onClick={() =>
                            !this.props.loading && this.toggleTooltip()
                          }
                        >
                          Save for Later
                        </button>

                        {this.state.isLaterTooltipActive && (
                          <OutsideAlerter>
                            <LaterTooltip
                              copiedToClipboard={this.state.copiedToClipboard}
                              sentEmail={this.state.sentEmail}
                              copyToClipboard={() => this.copyToClipboard()}
                              sendDraftOrder={() => this.sendDraftOrder()}
                              toggleTooltip={() => this.toggleTooltip()}
                              setState={(state) => this.setState(state)}
                            />
                          </OutsideAlerter>
                        )}
                      </div>
                    ) : (
                      <button
                        className="hover:bg-ezice-blue-800 p-2 w-48 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                        onClick={() =>
                          !this.props.loading &&
                          this.props.calculateUpgradePrice({
                            styleLeft: this.state.styleLeft,
                            styleMiddle: this.state.styleMiddle,
                            styleRight: this.state.styleRight,
                            rink: this.state.rink,
                            features: this.state.features,
                            customWidth: this.state.customWidth,
                            customLength: this.state.customLength,
                          })
                        }
                      >
                        {this.props.loading ? (
                          <ClipLoader color="#ffffff" size={25} />
                        ) : (
                          "See My Quote"
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="popup rink-popup-mobile" id="popup-dialog">
          <div className="popup-content" id="content">
            <div className="popup-content-container">
              <div className="popup-header calc-header">
                <div className="popup-header-left">
                  {this.state.isModalFeaturedStyles ? (
                    <span>Featured PRO Rink Styles</span>
                  ) : (
                    <span>Select the size that best fits your needs</span>
                  )}
                </div>

                <div className="popup-header-right">
                  <div className="popup-btnclose" onClick={this.toggleModal}>
                    <div className="popup-closeicon">X</div>
                  </div>
                </div>
              </div>

              {!this.state.renderCustomCalculator && (
                <div className="popup-body">
                  {this.state.isModalFeaturedStyles ? (
                    <div className="popup-body-right calc-body-right">
                      <div className="popup-step" id="step_1">
                        <div className="popup-step-options-container">
                          {PRESETS.map((preset, idx) => {
                            return (
                              <div
                                className="popup-step-options-item"
                                onClick={() => this.togglePreset(preset)}
                              >
                                {preset.id === "111" && (
                                  <img
                                    src="https://ezice.s3.amazonaws.com/rink-calc/imgc/popular.png"
                                    alt=""
                                    className="popup-popular"
                                  />
                                )}

                                <img
                                  src={this.getImageUrl(
                                    this.getNearestWidth(),
                                    this.getNearestLength(),
                                    this.state.showStraps,
                                    preset.id
                                  )}
                                  className="popup-step-image"
                                  onMouseOver={(e) =>
                                    (e.currentTarget.src = this.getImageUrl(
                                      this.getNearestWidth(),
                                      this.getNearestLength(),
                                      true,
                                      preset.id
                                    ))
                                  }
                                  onMouseOut={(e) =>
                                    (e.currentTarget.src = this.getImageUrl(
                                      this.getNearestWidth(),
                                      this.getNearestLength(),
                                      false,
                                      preset.id
                                    ))
                                  }
                                />
                                <div className="popup-step-options-item-price">
                                  <b>{`Style ${idx + 1}`}</b>
                                </div>
                                <div className="popup-step-options-item-title popup-step-options-item-title-preset">
                                  {preset.name}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="popup-body-right calc-body-right">
                      <div className="popup-step" id="step_1">
                        <div className="popup-step-options-container">
                          <div
                            className="popup-step-options-item"
                            onClick={() => this.toggleRink(SIZE_MAP.kiddie)}
                          >
                            <img
                              src={this.getImageUrl(15, 30)}
                              alt=""
                              className={
                                "popup-step-image" +
                                (this.state.rink === "kiddie"
                                  ? " popup-active-selection"
                                  : "")
                              }
                              onMouseOver={(e) =>
                                (e.currentTarget.src = this.getImageUrl(
                                  15,
                                  30,
                                  "Y"
                                ))
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.src = this.getImageUrl(15, 30))
                              }
                            />
                            <div className="popup-step-options-item-title">
                              {SIZE_MAP.kiddie.name} Rink (
                              {SIZE_MAP.kiddie.width}x{SIZE_MAP.kiddie.length}{" "}
                              ft)
                            </div>
                          </div>
                          <div
                            className="popup-step-options-item"
                            onClick={() => this.toggleRink(SIZE_MAP.small)}
                          >
                            <img
                              src={this.getImageUrl(20, 40)}
                              alt=""
                              className={
                                "popup-step-image" +
                                (!this.state.isCustom &&
                                this.state.rink === "small"
                                  ? " popup-active-selection"
                                  : "")
                              }
                              onMouseOver={(e) =>
                                (e.currentTarget.src = this.getImageUrl(
                                  20,
                                  40,
                                  "Y"
                                ))
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.src = this.getImageUrl(20, 40))
                              }
                            />
                            <div className="popup-step-options-item-title">
                              {SIZE_MAP.small.name} Rink ({SIZE_MAP.small.width}
                              x{SIZE_MAP.small.length} ft)
                            </div>
                          </div>
                          <div
                            className="popup-step-options-item"
                            onClick={() => this.toggleRink(SIZE_MAP.medium)}
                          >
                            <img
                              src={this.getImageUrl(25, 50)}
                              alt=""
                              className={
                                "popup-step-image" +
                                (!this.state.isCustom &&
                                this.state.rink === "medium"
                                  ? " popup-active-selection"
                                  : "")
                              }
                              onMouseOver={(e) =>
                                (e.currentTarget.src = this.getImageUrl(
                                  25,
                                  50,
                                  "Y"
                                ))
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.src = this.getImageUrl(25, 50))
                              }
                            />
                            <div className="popup-step-options-item-title">
                              {SIZE_MAP.medium.name} Rink (
                              {SIZE_MAP.medium.width}x{SIZE_MAP.medium.length}{" "}
                              ft)
                            </div>
                          </div>
                          <div
                            className="popup-step-options-item"
                            onClick={() => this.toggleRink(SIZE_MAP.large)}
                          >
                            <img
                              src={this.getImageUrl(30, 60)}
                              alt=""
                              className={
                                "popup-step-image" +
                                (!this.state.isCustom &&
                                this.state.rink === "large"
                                  ? " popup-active-selection"
                                  : "")
                              }
                              onMouseOver={(e) =>
                                (e.currentTarget.src = this.getImageUrl(
                                  30,
                                  60,
                                  "Y"
                                ))
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.src = this.getImageUrl(30, 60))
                              }
                            />
                            <div className="popup-step-options-item-title">
                              {SIZE_MAP.large.name} Rink ({SIZE_MAP.large.width}
                              x{SIZE_MAP.large.length} ft)
                            </div>
                          </div>
                          <div
                            className="popup-step-options-item"
                            onClick={() => this.toggleRink(SIZE_MAP.xl)}
                          >
                            <img
                              src={this.getImageUrl(40, 80)}
                              alt=""
                              className={
                                "popup-step-image" +
                                (!this.state.isCustom &&
                                this.state.rink === "xl"
                                  ? " popup-active-selection"
                                  : "")
                              }
                              onMouseOver={(e) =>
                                (e.currentTarget.src = this.getImageUrl(
                                  40,
                                  80,
                                  "Y"
                                ))
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.src = this.getImageUrl(40, 80))
                              }
                            />
                            <div className="popup-step-options-item-title">
                              {SIZE_MAP.xl.name} Rink ({SIZE_MAP.xl.width}x
                              {SIZE_MAP.xl.length} ft)
                            </div>
                          </div>
                          <div
                            className="popup-step-options-item"
                            onClick={() => this.toggleRink(SIZE_MAP.xxl)}
                          >
                            <img
                              src={this.getImageUrl(60, 100)}
                              alt=""
                              className={
                                "popup-step-image" +
                                (!this.state.isCustom &&
                                this.state.rink === "xxl"
                                  ? " popup-active-selection"
                                  : "")
                              }
                              onMouseOver={(e) =>
                                (e.currentTarget.src = this.getImageUrl(
                                  60,
                                  100,
                                  "Y"
                                ))
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.src = this.getImageUrl(
                                  60,
                                  100
                                ))
                              }
                            />
                            <div className="popup-step-options-item-title">
                              {SIZE_MAP.xxl.name} Rink ({SIZE_MAP.xxl.width}x
                              {SIZE_MAP.xxl.length} ft)
                            </div>
                          </div>
                          <div
                            className="popup-step-options-item"
                            onClick={() => this.toggleRink(SIZE_MAP.parks_rec)}
                          >
                            <img
                              src={this.getImageUrl(80, 120)}
                              alt=""
                              className={
                                "popup-step-image" +
                                (!this.state.isCustom &&
                                this.state.rink === "parks_rec"
                                  ? " popup-active-selection"
                                  : "")
                              }
                              onMouseOver={(e) =>
                                (e.currentTarget.src = this.getImageUrl(
                                  80,
                                  120,
                                  "Y"
                                ))
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.src = this.getImageUrl(
                                  80,
                                  120
                                ))
                              }
                            />
                            <div className="popup-step-options-item-title">
                              {SIZE_MAP.parks_rec.name} Rink (
                              {SIZE_MAP.parks_rec.width}x
                              {SIZE_MAP.parks_rec.length} ft)
                            </div>
                          </div>
                          <div
                            className="popup-step-options-item"
                            onClick={() => this.toggleRink(SIZE_MAP.nhl)}
                          >
                            <img
                              src={this.getImageUrl(85, 200)}
                              alt=""
                              className={
                                "popup-step-image" +
                                (!this.state.isCustom &&
                                this.state.rink === "nhl"
                                  ? " popup-active-selection"
                                  : "")
                              }
                              onMouseOver={(e) =>
                                (e.currentTarget.src = this.getImageUrl(
                                  85,
                                  200,
                                  "Y"
                                ))
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.src = this.getImageUrl(
                                  85,
                                  200
                                ))
                              }
                            />
                            <div className="popup-step-options-item-title">
                              {SIZE_MAP.nhl.name} Rink ({SIZE_MAP.nhl.width}x
                              {SIZE_MAP.nhl.length} ft)
                            </div>
                          </div>

                          <div className="popup-step-options-item">
                            <div className="popup-custom-size-container">
                              <div className="popup-custom-size-title">
                                Custom
                              </div>
                              <div className="popup-custom-size-selects">
                                <div className="popup-rink-size-option">
                                  <select
                                    className="popup-rink-select"
                                    value={this.state.customWidth}
                                    onChange={this.handleWidthChange}
                                  >
                                    <option value="10">10 Feet</option>
                                    <option value="15">15 Feet</option>
                                    <option value="20">20 Feet</option>
                                    <option value="25">25 Feet</option>
                                    <option value="30">30 Feet</option>
                                    <option value="35">35 Feet</option>
                                    <option value="40">40 Feet</option>
                                    <option value="45">45 Feet</option>
                                    <option value="50">50 Feet</option>
                                    <option value="55">55 Feet</option>
                                    <option value="60">60 Feet</option>
                                    <option value="65">65 Feet</option>
                                    <option value="70">70 Feet</option>
                                    <option value="75">75 Feet</option>
                                    <option value="80">80 Feet</option>
                                    <option value="85">85 Feet</option>
                                    <option value="90">90 Feet</option>
                                    <option value="95">95 Feet</option>
                                    <option value="100">100 Feet</option>
                                  </select>
                                  <div className="popup-rink-title">Width</div>
                                </div>

                                <div className="popup-rink-size-option">
                                  <select
                                    className="popup-rink-select"
                                    value={this.state.customLength}
                                    onChange={this.handleLengthChange}
                                  >
                                    <option value="10">10 Feet</option>
                                    <option value="15">15 Feet</option>
                                    <option value="20">20 Feet</option>
                                    <option value="25">25 Feet</option>
                                    <option value="30">30 Feet</option>
                                    <option value="35">35 Feet</option>
                                    <option value="40">40 Feet</option>
                                    <option value="45">45 Feet</option>
                                    <option value="50">50 Feet</option>
                                    <option value="55">55 Feet</option>
                                    <option value="60">60 Feet</option>
                                    <option value="65">65 Feet</option>
                                    <option value="70">70 Feet</option>
                                    <option value="75">75 Feet</option>
                                    <option value="80">80 Feet</option>
                                    <option value="85">85 Feet</option>
                                    <option value="90">90 Feet</option>
                                    <option value="95">95 Feet</option>
                                    <option value="100">100 Feet</option>
                                    <option value="105">105 Feet</option>
                                    <option value="110">110 Feet</option>
                                    <option value="115">115 Feet</option>
                                    <option value="120">120 Feet</option>
                                    <option value="125">125 Feet</option>
                                    <option value="130">130 Feet</option>
                                    <option value="135">135 Feet</option>
                                    <option value="140">140 Feet</option>
                                    <option value="145">145 Feet</option>
                                    <option value="150">150 Feet</option>
                                    <option value="155">155 Feet</option>
                                    <option value="160">160 Feet</option>
                                    <option value="165">165 Feet</option>
                                    <option value="170">170 Feet</option>
                                    <option value="175">175 Feet</option>
                                    <option value="180">180 Feet</option>
                                    <option value="185">185 Feet</option>
                                    <option value="190">190 Feet</option>
                                    <option value="195">195 Feet</option>
                                    <option value="200">200 Feet</option>
                                  </select>
                                  <div className="popup-rink-title">Length</div>
                                </div>
                              </div>
                              <button
                                className="rink-selection-button popup-custom-rink-button"
                                onClick={this.saveCustom}
                              >
                                Select
                              </button>
                            </div>
                            <div className="popup-step-options-item-title">
                              Custom Rink
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/*{ this.state.renderCustomCalculator &&
                <div className="popup-body">
                  <div className="popup-body-calc">
                    <div>Looking for a custom size? The 60 minute backyard rink™ comes in any width and length in increments of 5 feet.
         Looking for a custom size? The 60 minute backyard rink™ comes in any width and length in increments of 5 feet.</div>
                    <canvas id="canvas" className="popup-canvas"></canvas>
                  </div>
                </div>
              }

              { !this.state.renderCustomCalculator &&
                <div className="popup-footer">
                  <div className="popup-footer-left">
                    Looking for a custom size? The 60 minute backyard rink™ comes in any width and length in increments of 5 feet.
                  </div>
                  <div className="popup-footer-right">
                   <button className="popup-header-right-buy popup-footer-button" onClick={() => this.renderCalc()}>Customize</button>
                  </div>
                </div>
              }

              { this.state.renderCustomCalculator &&
                <div className="popup-footer">
                  <div className="popup-footer-left">
                    Looking for a preset size? The 60 minute backyard rink™ comes in 72 preset options.
                  </div>
                  <div className="popup-footer-right">
                   <button className="popup-header-right-buy popup-footer-button" onClick={() => this.setState({ renderCustomCalculator: false })}>Standard</button>
                  </div>
                </div>
              } */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

class LaterTooltip extends React.Component {
  render() {
    return (
      <div className="popup-later">
        <div
          className="popup-btnclose popup-sm-btnclose "
          onClick={() => this.props.toggleTooltip()}
        >
          <div className="popup-closeicon popup-sm-closeicon">X</div>
        </div>

        <div className="popup-later-title">
          Copy, text or email the link below to share your custom EZ ICE PRO
          Home Arena System with friends!
        </div>
        <div className="popup-later-contents">
          <div className="popup-later-phone">
            <input
              className="popup-later-input"
              value={window.location.href}
              placeholder="Phone number"
              disabled
            />
            <button
              className={
                "popup-later-button" +
                (this.props.copiedToClipboard
                  ? " popup-later-button-active"
                  : "")
              }
              onClick={() => this.props.copyToClipboard()}
            >
              {this.props.copiedToClipboard ? "Copied!" : "Copy"}
            </button>
          </div>
        </div>
        <div className="popup-later-contents">
          <div className="popup-later-email">
            <input
              className="popup-later-input"
              placeholder="Email"
              onChange={(e) => this.props.setState({ email: e.target.value })}
            />
            <button
              className={
                "popup-later-button" +
                (this.props.sentEmail ? " popup-later-button-active" : "")
              }
              onClick={() => this.props.sendDraftOrder()}
            >
              {this.props.sentEmail ? "Emailed!" : "Email Me"}
            </button>
          </div>
        </div>
        <div className="popup-later-arrow"></div>
      </div>
    );
  }
}

class OutsideAlerter extends React.Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.children.props.toggleTooltip();
    }
  }

  render() {
    return (
      <div className="popup-alerter-wrapper" ref={this.setWrapperRef}>
        {this.props.children}
      </div>
    );
  }
}

$(".popup-entry").click(function () {
  $(".popup").css("display", "block");
});

$(function () {
  // if (DEV_MODE == true) {
  //   document.getElementById('popup-dialog').classList.add('show')
  //   const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
  //   const body = document.body;
  //   // body.style.position = 'fixed';
  //   body.style.top = `-${scrollY}`;
  // }

  $("select").each(function () {
    var select = $(this);
    var selectedValue = select.find("option[selected]").val();

    if (selectedValue) {
      select.val(selectedValue);
    }
  });
});

const showDialog = () => {
  document.getElementById("popup-dialog").classList.add("show");
  // $(".header").css( "display", "none" );
  // const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
  // const body = document.body;
  // body.style.position = 'fixed';
  // body.style.top = `-${scrollY}`;
};
const closeDialog = () => {
  // const body = document.body;
  // $(".header").css( "display", "block" );
  // const scrollY = body.style.top;
  // body.style.position = '';
  // body.style.top = '';
  // window.scrollTo(0, parseInt(scrollY || '0') * -1);
  document.getElementById("popup-dialog").classList.remove("show");
};
// window.addEventListener('scroll', () => {
//   document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
// });

function insertQueryParam(key, value) {
  if (window.history.pushState) {
    let searchParams = new URLSearchParams(window.location.search);
    let nav = window.location.href.split("#")[1];
    searchParams.set(key, value);

    let newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      searchParams.toString() +
      (nav == "undefined" || !nav ? "" : `#${nav}`);
    window.history.pushState({ path: newurl }, "", newurl);
  }
}
function getQueryParam(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
function removeQueryParam(parameter) {
  var url = document.location.href;
  var urlparts = url.split("?");

  if (urlparts.length >= 2) {
    var urlBase = urlparts.shift();
    var queryString = urlparts.join("?");

    var prefix = encodeURIComponent(parameter) + "=";
    var pars = queryString.split(/[&;]/g);
    for (var i = pars.length; i-- > 0; )
      if (pars[i].lastIndexOf(prefix, 0) !== -1) pars.splice(i, 1);
    url = urlBase + "?" + pars.join("&");
    window.history.pushState("", document.title, url);
  }

  return url;
}

export default Calc;
