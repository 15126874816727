import React from "react";
import { Fragment, useState, useEffect } from "react";

import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import axios from "axios";
import Modal from "react-modal";
import ProgressBar from "@ramonak/react-progress-bar";
import "react-toastify/dist/ReactToastify.css";

import ReactTooltip from "react-tooltip";
import { ClipLoader } from "react-spinners";
import { getRoot, numberWithCommas } from "./helpers";
import { humanizeSides } from "@ezicerinks/js-utilities";

import { ArrowSmLeftIcon, ArrowSmRightIcon } from "@heroicons/react/outline";

import { useNavigate } from "react-router-dom";

import Calc from "./Calc";

const NUM_STAGES = 14;
const ROOT_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : "https://ezice.herokuapp.com";

const SIZE_MAP = {
  kiddie: { width: 15, length: 30, price: 2550, id: "kiddie", name: "Kiddie" },
  small: { width: 20, length: 40, price: 3280, id: "small", name: "Small" },
  medium: { width: 25, length: 50, price: 4030, id: "medium", name: "Medium" },
  large: { width: 30, length: 60, price: 4830, id: "large", name: "Large" },
  xl: { width: 40, length: 80, price: 6470, id: "xl", name: "XL" },
  xxl: { width: 60, length: 100, price: 8920, id: "xxl", name: "XXL" },
  parks_rec: {
    width: 80,
    length: 120,
    price: 11590,
    id: "parks_rec",
    name: "Parks & Rec",
  },
  nhl: { width: 85, length: 200, price: 18040, id: "nhl", name: "NHL Size" },
};

const STYLE_MAP = {
  1: "C",
  2: "D",
  3: "A",
  4: "N",
};
const STYLE_MAP_REV = {
  C: 1,
  D: 2,
  A: 3,
  N: 4,
};

// const MODAL_STAGES = {
//   0: "get_started",
//   1: "calc",
//   2: "current_rink_intro",
//   3: "current_rink_input",
//   4: "current_rink_manual",
//   5: "",
//   6: "",
//   7: "",
// };

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-30%",
    padding: "0px",
    transform: "translate(-50%, -55%)",
    backgroundColor: "transparent",
    border: "none",
  },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Login() {
  const [loading, setLoading] = useState(false);

  const [modalIndex, setModalIndex] = useState(0);
  const [currentRinkUrl, setCurrentRinkUrl] = useState("");
  const [upgradeRinkUrl, setUpgradeRinkUrl] = useState("");

  const [urlWidth, setUrlWidth] = useState(0);
  const [urlLength, setUrlLength] = useState(0);

  const [lastName, setLastName] = useState("");
  const [orderNumber, setOrderNumber] = useState("");

  const [forgotOrder, setForgotOrder] = useState(false);
  const [forgotOrderSubmitted, setForgotOrderSubmitted] = useState(false);
  const [forgotEmailAddress, setForgotEmailAddress] = useState("");

  const [upgradeRink, setUpgradeRink] = useState({});
  const [currentRink, setCurrentRink] = useState({
    width: 30,
    length: 60,
    left: "C",
    middle: "C",
    right: "C",
    corners: "R",
    bumpers: "B",
    type: "P",
  });

  const [upgradePrice, setUpgradePrice] = useState(0);
  const [upgradeBoms, setUpgradeBoms] = useState(0);
  const [liner, setLiner] = useState({});
  const [shopifyLinerId, setShopifyLinerId] = useState(0);

  useEffect(() => {
    let width =
      currentRink.width < currentRink.length
        ? currentRink.width
        : currentRink.length;
    let length =
      currentRink.length > currentRink.width
        ? currentRink.length
        : currentRink.width;

    const left = STYLE_MAP_REV[currentRink.left];
    const middle = STYLE_MAP_REV[currentRink.middle];
    const right = STYLE_MAP_REV[currentRink.right];
    const styleString = `${left}${middle}${right}`;

    const converted = getImageUrlDimensions(width);
    width = converted.width;
    length = converted.length;

    setCurrentRinkUrl(
      getImageUrl(
        width,
        length,
        styleString,
        currentRink.corners,
        currentRink.bumpers
      )
    );

    setUrlWidth(width);
    setUrlLength(length);
  }, [currentRink]);

  useEffect(() => {
    if (!upgradeRink.width) {
      return;
    }

    let width =
      upgradeRink.width < upgradeRink.length
        ? upgradeRink.width
        : upgradeRink.length;
    let length =
      upgradeRink.length > upgradeRink.width
        ? upgradeRink.length
        : upgradeRink.width;

    const left = STYLE_MAP_REV[upgradeRink.left];
    const middle = STYLE_MAP_REV[upgradeRink.middle];
    const right = STYLE_MAP_REV[upgradeRink.right];
    const styleString = `${left}${middle}${right}`;

    const converted = getImageUrlDimensions(width);
    width = converted.width;
    length = converted.length;

    setUpgradeRinkUrl(
      getImageUrl(
        width,
        length,
        styleString,
        upgradeRink.corners,
        upgradeRink.bumpers
      )
    );
  }, [upgradeRink]);

  async function sendForgotOrder() {
    setForgotOrderSubmitted(true);

    const resp = await axios.post(`${getRoot()}/api/orders/forgot`, {
      email: forgotEmailAddress,
    });
  }

  // EZ ICE PRO Home Arena System ™ – Upgrade from [ORG // 20ft * 40ft // Net-Classic-Double // Square Corners // No Bumpers // {room for future variants}] to [PRO // 20ft * 55ft // Net-Classic-Net // Rounded Corners // With Bumpers]
  async function addToCart() {
    setLoading(true);
    const description = `EZ ICE PRO Home Arena System ™ – Upgrade from [${
      currentRink.type === "O" ? "ORG" : "PRO"
    } // ${currentRink.width}ft * ${currentRink.length}ft // ${humanizeSides(
      currentRink.left,
      currentRink.middle,
      currentRink.right
    )} // ${currentRink.corners == "S" ? "Square" : "Round"} Corners // ${
      currentRink.bumpers == "B" ? "With" : "No"
    } Bumpers] to [${upgradeRink.type === "O" ? "ORG" : "PRO"} // ${
      upgradeRink.width
    }ft * ${upgradeRink.length}ft // ${humanizeSides(
      upgradeRink.left,
      upgradeRink.middle,
      upgradeRink.right
    )} // ${upgradeRink.corners == "S" ? "Square" : "Round"} Corners // ${
      upgradeRink.bumpers == "B" ? "With" : "No"
    } Bumpers]`;

    const resp = await axios.post(`${ROOT_URL}/api/upgrades/save_quote`, {
      boms: upgradeBoms,
      upgrade_price: upgradePrice,
      liner: liner.sku,
      rink_image_url: upgradeRinkUrl,
      description,
    });

    window.parent.postMessage(
      {
        items: [
          {
            quantity: 1,
            id: resp.data.upgrade_shopify_variant_id,
          },
        ],
      },
      "*"
    );

    setLoading(false);

    setModalIndex(14);
    setShopifyLinerId(resp.data.liner_shopify_variant_id);
  }

  async function getCurrentRink() {
    const currentRink = (
      await axios.post(`${getRoot()}/api/orders/current_rink`, {
        order_number: orderNumber,
        last_name: lastName,
      })
    ).data;

    if (currentRink.width) {
      setCurrentRink(currentRink);
      setModalIndex(9);
    } else {
      toast.error("Unable to find a record of your rink.");
    }
  }

  async function calculateUpgradePrice(newUpgradeRink) {
    const width = getQueryParam("custom_width")
      ? newUpgradeRink.customWidth
      : SIZE_MAP[newUpgradeRink.rink].width;
    const length = getQueryParam("custom_length")
      ? newUpgradeRink.customLength
      : SIZE_MAP[newUpgradeRink.rink].length;
    const left = STYLE_MAP[parseInt(newUpgradeRink.styleLeft)];
    const middle = STYLE_MAP[parseInt(newUpgradeRink.styleMiddle)];
    const right = STYLE_MAP[parseInt(newUpgradeRink.styleRight)];
    const corners = newUpgradeRink.features.includes("rounded") ? "R" : "S";
    const bumpers = newUpgradeRink.features.includes("bumpers") ? "B" : "X";
    const type = currentRink.type || "P";

    setUpgradeRink({
      width,
      length,
      left,
      middle,
      right,
      corners,
      bumpers,
      type,
    });

    setLoading(true);
    const response = (
      await axios.post(`${getRoot()}/api/upgrades/request_quote`, {
        is_original_upgrade: currentRink.type === "O",
        old_width: currentRink.width,
        old_length: currentRink.length,
        old_style: `${currentRink.left}${currentRink.middle}${currentRink.right}`,
        old_features: `${currentRink.corners}${currentRink.bumpers}X`,

        new_width: width,
        new_length: length,
        new_style: `${left}${middle}${right}`,
        new_features: `${corners}${bumpers}X`,
      })
    ).data;
    setLoading(false);

    setUpgradePrice(response.upgrade_price);
    setUpgradeBoms(response.boms);
    setLiner({
      sku: response.liner,
      price: response.liner_price,
      title: response.liner_title,
    });
  }

  function goBack() {
    if (modalIndex === 11) {
      setModalIndex(9);
    } else if (modalIndex === 9) {
      setModalIndex(7);
    } else {
      setModalIndex(modalIndex - 1);
    }
  }

  function goForward() {
    if (modalIndex === 6 || modalIndex === 7) {
      setModalIndex(9);
    } else {
      setModalIndex(modalIndex + 1);
    }
  }

  function goToRink() {
    insertQueryParam("custom_width", currentRink.width);
    insertQueryParam("custom_length", currentRink.length);
    insertQueryParam(
      "style",
      [
        transposeStyleToNum(currentRink.left),
        transposeStyleToNum(currentRink.middle),
        transposeStyleToNum(currentRink.right),
      ].join(",")
    );

    const features = [];

    if (currentRink.corners == "R") {
      features.push("rounded");
    }
    if (currentRink.bumpers == "B") {
      features.push("bumpers");
    }

    insertQueryParam("features", features.join(","));

    setModalIndex(11);
  }

  function shouldRenderBack() {
    return modalIndex >= 2 && modalIndex !== 10 && modalIndex < 13;
  }

  function shouldRenderForward() {
    return (
      modalIndex > 1 &&
      modalIndex !== 9 &&
      modalIndex !== 10 &&
      modalIndex !== 11 &&
      modalIndex < 13
    );
  }

  function transposeStyleToNum(letter) {
    if (letter == "C") {
      return "1";
    } else if (letter == "D") {
      return "2";
    } else if (letter == "A") {
      return "3";
    } else {
      return "4";
    }
  }

  async function checkout(shouldAddLiner) {
    setLoading(true);

    if (shouldAddLiner) {
      window.parent.postMessage(
        {
          items: [
            {
              quantity: 1,
              id: shopifyLinerId,
            },
          ],
        },
        "*"
      );
    }

    await new Promise((r) => setTimeout(r, 1000));

    window.parent.postMessage(
      {
        goToCart: true,
      },
      "*"
    );

    setLoading(false);
  }

  function setCurrentRinkFields(rinkFields) {
    const newRink = { ...currentRink };
    if (rinkFields.width) {
      newRink.width = rinkFields.width;
    }
    if (rinkFields.length) {
      newRink.length = rinkFields.length;
    }
    if (rinkFields.left || rinkFields.middle || rinkFields.right) {
      newRink.left = rinkFields.left || newRink.left;
      newRink.middle = rinkFields.middle || newRink.middle;
      newRink.right = rinkFields.right || newRink.right;

      const left = STYLE_MAP_REV[newRink.left];
      const middle = STYLE_MAP_REV[newRink.middle];
      const right = STYLE_MAP_REV[newRink.right];

      // After increasing middle, bring up sides if middle is highest.
      if (rinkFields.middle) {
        if (left < middle) {
          newRink.left = STYLE_MAP[middle];
        }
        if (right < middle) {
          newRink.right = STYLE_MAP[middle];
        }
        // After decreasing side, bring down middle if middle is highest.
      } else {
        if (middle > left || middle > right) {
          newRink.middle = STYLE_MAP[Math.min(left, right)];
        }
      }
    }
    if (rinkFields.corners) {
      newRink.corners = rinkFields.corners;
    }
    if (rinkFields.bumpers) {
      newRink.bumpers = rinkFields.bumpers;
    }
    if (rinkFields.type) {
      newRink.type = rinkFields.type;
    }

    setCurrentRink(newRink);
  }

  function getImageUrlDimensions(width) {
    if (width >= 10 && width <= 15) {
      return { width: 15, length: 30 };
    } else if (width > 15 && width <= 20) {
      return { width: 20, length: 40 };
    } else if (width > 20 && width <= 25) {
      return { width: 25, length: 50 };
    } else if (width > 25 && width <= 35) {
      return { width: 30, length: 60 };
    } else if (width > 35 && width <= 50) {
      return { width: 40, length: 80 };
    } else if (width > 50 && width <= 70) {
      return { width: 60, length: 100 };
    } else if (width > 70 && width <= 80) {
      return { width: 80, length: 120 };
    } else if (width > 80) {
      return { width: 85, length: 200 };
    }
  }
  function getImageUrl(width, length, styleString, corners, bumpers) {
    const rinkSize = `${width}x${length}`;
    const urlBumpers = bumpers === "B" ? "Y" : "N";

    let style;
    if (styleString == "111") {
      style = "01";
    } else if (styleString == "222") {
      style = "02";
    } else if (styleString == "333") {
      style = "03";
    } else if (styleString == "444") {
      style = "04";
    } else if (styleString == "414") {
      style = "05";
    } else if (styleString == "313") {
      style = "06";
    } else if (styleString == "212") {
      style = "07";
    } else if (styleString == "323") {
      style = "08";
    } else if (styleString == "424") {
      style = "09";
    } else if (styleString == "114") {
      style = "10";
    } else if (styleString == "113") {
      style = "11";
    } else if (styleString == "112") {
      style = "12";
    } else if (styleString == "223") {
      style = "13";
    } else if (styleString == "224") {
      style = "14";
    } else if (styleString == "411") {
      style = "15";
    } else if (styleString == "311") {
      style = "16";
    } else if (styleString == "211") {
      style = "17";
    } else if (styleString == "322") {
      style = "18";
    } else if (styleString == "422") {
      style = "19";
    } else if (styleString == "434") {
      style = "20";
    } else if (styleString == "334") {
      style = "21";
    } else if (styleString == "433") {
      style = "22";
    } else if (styleString == "213") {
      style = "23";
    } else if (styleString == "214") {
      style = "24";
    } else if (styleString == "314") {
      style = "25";
    } else if (styleString == "312") {
      style = "26";
    } else if (styleString == "412") {
      style = "27";
    } else if (styleString == "413") {
      style = "28";
    } else if (styleString == "423") {
      style = "29";
    } else if (styleString == "324") {
      style = "30";
    }

    return `https://ezice.s3.amazonaws.com/rink-calc/imgc/${rinkSize}_${corners}_${urlBumpers}_Y_${style}.jpg`;
  }

  function generateRinkName(
    width,
    length,
    left,
    middle,
    right,
    corners,
    bumpers
  ) {
    const sides = `${left}${middle}${right}`;

    let sideNaming;
    if (sides == "CCC") {
      sideNaming = "Classic Height";
    } else if (sides == "DDD") {
      sideNaming = "Double Height";
    } else if (sides == "AAA") {
      sideNaming = "Arena Height";
    } else if (sides == "NNN") {
      sideNaming = "Arena Height plus Netting";
    } else if (sides == "NCN") {
      sideNaming = "Classic Height plus Arena/Netting Ends";
    } else if (sides == "ACA") {
      sideNaming = "Classic Height plus Arena Ends";
    } else if (sides == "DCD") {
      sideNaming = "Classic Height plus Double Ends";
    } else if (sides == "ADA") {
      sideNaming = "Double Height plus Arena Ends";
    } else if (sides == "NDN") {
      sideNaming = "Double Height plus Arena/Netting Ends";
    } else if (sides == "CCN") {
      sideNaming = "Classic Height plus One Arena/Netting End";
    } else if (sides == "CCA") {
      sideNaming = "Classic Height plus One Arena End";
    } else if (sides == "CCD") {
      sideNaming = "Classic Height plus One Double End";
    } else if (sides == "DDA") {
      sideNaming = "Double Height plus One Arena End";
    } else if (sides == "DDN") {
      sideNaming = "Double Height plus One Arena/Netting End";
    } else if (sides == "NCC") {
      sideNaming = "Classic Height plus One Arena/Netting End";
    } else if (sides == "ACC") {
      sideNaming = "Classic Height plus One Arena End";
    } else if (sides == "DCC") {
      sideNaming = "Classic Height plus One Double End";
    } else if (sides == "ADD") {
      sideNaming = "Double Height plus One Arena End";
    } else if (sides == "NDD") {
      sideNaming = "Double Height plus One Arena/Netting End";
    } else if (sides == "NAN") {
      sideNaming = "Arena Height plus Arena/Netting Ends";
    } else if (sides == "AAN") {
      sideNaming = "Arena Height plus One Arena/Netting End";
    } else if (sides == "NAA") {
      sideNaming = "Arena Height plus One Arena/Netting End";
    } else if (sides == "DCA") {
      sideNaming = "Classic Height plus Arena End, Double End";
    } else if (sides == "DCN") {
      sideNaming = "Classic Height plus Arena/Netting End, Double End";
    } else if (sides == "ACN") {
      sideNaming = "Classic Height plus Arena/Netting End, Arena End";
    } else if (sides == "ACD") {
      sideNaming = "Classic Height plus Arena End, Double End";
    } else if (sides == "NCD") {
      sideNaming = "Classic Height plus Arena/Netting End, Double End";
    } else if (sides == "NCA") {
      sideNaming = "Classic Height plus Arena/Netting End, Arena End";
    } else if (sides == "NDA") {
      sideNaming = "Double Height plus Arena/Netting End, Arena End";
    } else if (sides == "ADN") {
      sideNaming = "Double Height plus Arena/Netting End, Arena End";
    }

    return `${width}' x ${length}' ${sideNaming} with ${
      corners === "S" ? "Square" : "Round"
    } Corners${bumpers === "B" ? " and Bumpers" : ""}`;
  }

  return (
    <div className="w-full h-screen flex-col bg-skating bg-cover justify-center items-center">
      <Modal isOpen={true} style={customStyles}>
        {modalIndex > 1 && (
          <ProgressBar
            bgColor="#233562"
            completed={Math.round((100 * modalIndex) / NUM_STAGES / 5) * 5}
          />
        )}
        {/* {modalIndex} */}
        <div className="mt-2 bg-white p-2 border-8 rounded-3xl border-ezice-blue-600">
          {modalIndex == 0 && (
            <div className="flex flex-col items-center">
              <div className="ezice-upgrade-title center text-3xl font-suigeneris text-ezice-blue-600">
                Upgrade Now
              </div>
              <button
                className="hover:bg-ezice-blue-800 bk5:mt-0 bk5:text-sm bk5:w-32 bk5:p-0 mb-4 mt-4 w-36 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                onClick={() => setModalIndex(1)}
              >
                Get Started
                <ArrowSmRightIcon
                  className="h-12 w-8 text-white"
                  aria-hidden="true"
                />
              </button>
              <div className="flex center">
                <img
                  className="w-[600px] center"
                  src="https://ezice.s3.amazonaws.com/upgrade-calc/img/example_rinks.jpg"
                ></img>
              </div>
            </div>
          )}

          {modalIndex == 1 && (
            <div className="flex flex-col items-center justify-center">
              {!forgotOrder && (
                <Fragment>
                  <h5 className="text-center mb-4 font-bold text-ezice-blue-600">
                    First, tell us about your existing rink.
                  </h5>

                  <div className="flex flex-col items-center justify-center">
                    <div>Order Number</div>
                    <input
                      className="w-32 border-2 rounded-3xl border-ezice-blue-600 px-2"
                      value={orderNumber}
                      onChange={(e) => setOrderNumber(e.target.value)}
                      onKeyDown={(event) =>
                        event.key === "Enter" && getCurrentRink()
                      }
                    ></input>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <div>Last Name</div>
                    <input
                      className="w-32 border-2 rounded-3xl border-ezice-blue-600 px-2"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      onKeyDown={(event) =>
                        event.key === "Enter" && getCurrentRink()
                      }
                    ></input>
                  </div>

                  <div
                    className="mb-4 text-sm hover:cursor-pointer underline"
                    onClick={() => setForgotOrder(true)}
                  >
                    Forgot Order Number
                  </div>

                  <button
                    className="hover:bg-ezice-blue-800 bk5:mt-0 bk5:text-sm bk5:w-24 bk5:p-0 p-2 text-lg w-28 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                    onClick={getCurrentRink}
                  >
                    Submit
                  </button>
                </Fragment>
              )}

              {forgotOrder && (
                <Fragment>
                  <h5 className="text-center mb-4 font-bold text-ezice-blue-600">
                    Forgot Order Number
                  </h5>

                  <div className="flex flex-col items-center justify-center">
                    <div>Email Address</div>
                    <input
                      className="w-60 border-2 rounded-3xl border-ezice-blue-600 px-2"
                      type="email"
                      value={forgotEmailAddress}
                      onChange={(e) => setForgotEmailAddress(e.target.value)}
                    ></input>
                  </div>

                  {!forgotOrderSubmitted && (
                    <button
                      className="hover:bg-ezice-blue-800 bk5:mt-0 bk5:text-sm bk5:w-24 bk5:p-0 mt-2 p-1 text-lg w-28 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                      onClick={sendForgotOrder}
                    >
                      Submit
                    </button>
                  )}
                  {forgotOrderSubmitted && (
                    <div className="w-72 mt-4 text-center">
                      Your latest order confirmation email, containing your
                      order number, has been sent to the address provided.
                    </div>
                  )}
                </Fragment>
              )}

              <hr className="w-72 border-2 border-ezice-blue-600 my-4" />

              <button
                className="hover:bg-ezice-blue-800 bk5:mt-0 bk5:text-sm bk5:w-48 bk5:p-0 p-2 w-60 font-bold text-xl text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                onClick={() => setModalIndex(2)}
              >
                Or Upgrade Manually
              </button>

              <div className="text-center">
                Enter your existing rink dimensions manually
              </div>
            </div>
          )}

          {modalIndex == 2 && (
            <div className="flex flex-col items-center justify-center">
              <h5 className="text-center mb-4 italic text-ezice-blue-600">
                Please enter the details of your existing rink below.
              </h5>

              <h4 className="text-center bk5:text-base mb-4 font-bold text-ezice-blue-600">
                First, what type of corners do you have?
              </h4>
              <div className="flex items-center justify-center">
                <button
                  className={classNames(
                    currentRink.corners === "S"
                      ? ""
                      : "bg-ezice-blue-100 hover:bg-ezice-blue-800",
                    "center mr-2 p-2 text-lg w-28 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                  )}
                  onClick={() => setCurrentRinkFields({ corners: "S" })}
                >
                  Square
                </button>
                <button
                  className={classNames(
                    currentRink.corners === "R"
                      ? ""
                      : "bg-ezice-blue-100 hover:bg-ezice-blue-800",
                    "center mr-2 p-2 text-lg w-28 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                  )}
                  onClick={() => setCurrentRinkFields({ corners: "R" })}
                >
                  Round
                </button>
              </div>

              <img className="w-[400px]" src={currentRinkUrl} />
              <div className="text-sm text-slate-400 italic -mt-6">
                ({urlWidth}' x {urlLength}' Rink Shown)
              </div>
            </div>
          )}

          {modalIndex == 3 && (
            <div className="flex flex-col items-center justify-center">
              <h5 className="text-center mb-4 italic text-ezice-blue-600">
                Please enter the details of your existing rink below
              </h5>

              <h4 className="text-center bk5:text-base font-bold text-ezice-blue-600">
                Nice! Next question.
              </h4>
              <h4 className="text-center bk5:text-base mb-4 font-bold text-ezice-blue-600">
                What size is your existing rink?
              </h4>
              <div className="flex items-center justify-center">
                <div className="popup-rink-size-option">
                  <div className="popup-rink-title">Width</div>

                  <select
                    className="popup-rink-select"
                    value={currentRink.width}
                    onChange={(e) =>
                      setCurrentRinkFields({ width: e.target.value })
                    }
                  >
                    <option value="10">10 Feet</option>
                    <option value="15">15 Feet</option>
                    <option value="20">20 Feet</option>
                    <option value="25">25 Feet</option>
                    <option value="30">30 Feet</option>
                    <option value="35">35 Feet</option>
                    <option value="40">40 Feet</option>
                    <option value="45">45 Feet</option>
                    <option value="50">50 Feet</option>
                    <option value="55">55 Feet</option>
                    <option value="60">60 Feet</option>
                    <option value="65">65 Feet</option>
                    <option value="70">70 Feet</option>
                    <option value="75">75 Feet</option>
                    <option value="80">80 Feet</option>
                    <option value="85">85 Feet</option>
                    <option value="90">90 Feet</option>
                    <option value="95">95 Feet</option>
                    <option value="100">100 Feet</option>
                  </select>
                </div>

                <div className="popup-rink-size-option">
                  <div className="popup-rink-title">Length</div>

                  <select
                    className="popup-rink-select"
                    value={currentRink.length}
                    onChange={(e) =>
                      setCurrentRinkFields({ length: e.target.value })
                    }
                  >
                    <option value="10">10 Feet</option>
                    <option value="15">15 Feet</option>
                    <option value="20">20 Feet</option>
                    <option value="25">25 Feet</option>
                    <option value="30">30 Feet</option>
                    <option value="35">35 Feet</option>
                    <option value="40">40 Feet</option>
                    <option value="45">45 Feet</option>
                    <option value="50">50 Feet</option>
                    <option value="55">55 Feet</option>
                    <option value="60">60 Feet</option>
                    <option value="65">65 Feet</option>
                    <option value="70">70 Feet</option>
                    <option value="75">75 Feet</option>
                    <option value="80">80 Feet</option>
                    <option value="85">85 Feet</option>
                    <option value="90">90 Feet</option>
                    <option value="95">95 Feet</option>
                    <option value="100">100 Feet</option>
                    <option value="105">105 Feet</option>
                    <option value="110">110 Feet</option>
                    <option value="115">115 Feet</option>
                    <option value="120">120 Feet</option>
                    <option value="125">125 Feet</option>
                    <option value="130">130 Feet</option>
                    <option value="135">135 Feet</option>
                    <option value="140">140 Feet</option>
                    <option value="145">145 Feet</option>
                    <option value="150">150 Feet</option>
                    <option value="155">155 Feet</option>
                    <option value="160">160 Feet</option>
                    <option value="165">165 Feet</option>
                    <option value="170">170 Feet</option>
                    <option value="175">175 Feet</option>
                    <option value="180">180 Feet</option>
                    <option value="185">185 Feet</option>
                    <option value="190">190 Feet</option>
                    <option value="195">195 Feet</option>
                    <option value="200">200 Feet</option>
                  </select>
                </div>
              </div>

              <img className="w-[400px]" src={currentRinkUrl} />
              <div className="text-sm text-slate-400 italic -mt-6">
                ({urlWidth}' x {urlLength}' Rink Shown)
              </div>
            </div>
          )}

          {modalIndex == 4 && (
            <div className="flex flex-col items-center justify-center">
              <h5 className="text-center mb-4 italic text-ezice-blue-600">
                Please enter the details of your existing rink below.
              </h5>

              <h4 className="text-center bk5:text-base font-bold text-ezice-blue-600">
                Great!
              </h4>
              <h4 className="text-center bk5:text-base mb-4 font-bold text-ezice-blue-600">
                What is the style of your existing rink?
              </h4>
              <div className="flex bk5:flex-col items-center justify-center">
                <div className="popup-rink-size-option">
                  <div className="popup-rink-title">Left End</div>

                  <select
                    className="popup-rink-select"
                    value={currentRink.left}
                    onChange={(e) =>
                      setCurrentRinkFields({ left: e.target.value })
                    }
                  >
                    <option value="C">Classic Height</option>
                    <option value="D">Double Height</option>
                    <option value="A">Arena Height</option>
                    <option value="N">Arena + Nets</option>
                  </select>
                </div>

                <div className="popup-rink-size-option">
                  <div className="popup-rink-title">Middle</div>

                  <select
                    className="popup-rink-select"
                    value={currentRink.middle}
                    onChange={(e) =>
                      setCurrentRinkFields({ middle: e.target.value })
                    }
                  >
                    <option value="C">Classic Height</option>
                    <option value="D">Double Height</option>
                    <option value="A">Arena Height</option>
                    <option value="N">Arena + Nets</option>
                  </select>
                </div>

                <div className="popup-rink-size-option">
                  <div className="popup-rink-title">Right End</div>

                  <select
                    className="popup-rink-select"
                    value={currentRink.right}
                    onChange={(e) =>
                      setCurrentRinkFields({ right: e.target.value })
                    }
                  >
                    <option value="C">Classic Height</option>
                    <option value="D">Double Height</option>
                    <option value="A">Arena Height</option>
                    <option value="N">Arena + Nets</option>
                  </select>
                </div>
              </div>

              <img className="w-[400px]" src={currentRinkUrl} />
              <div className="text-sm text-slate-400 italic -mt-6">
                ({urlWidth}' x {urlLength}' Rink Shown)
              </div>
            </div>
          )}

          {modalIndex == 5 && (
            <div className="flex flex-col items-center justify-center">
              <h5 className="text-center mb-4 italic text-ezice-blue-600">
                Please enter the details of your existing rink below
              </h5>

              <h4 className="text-center bk5:text-base font-bold text-ezice-blue-600">
                Almost done!
              </h4>
              <h4 className="text-center bk5:text-base mb-4 font-bold text-ezice-blue-600">
                Do you have foam bumpers on your existing rink?
              </h4>
              <div className="flex items-center justify-center">
                <div className="popup-rink-size-option">
                  <div className="popup-rink-title">Foam Bumpers</div>

                  <select
                    className="popup-rink-select"
                    value={currentRink.bumpers}
                    onChange={(e) =>
                      setCurrentRinkFields({ bumpers: e.target.value })
                    }
                  >
                    <option value="B">Foam Bumpers</option>
                    <option value="X">No Bumpers</option>
                  </select>
                </div>
              </div>

              <img className="w-[400px]" src={currentRinkUrl} />
              <div className="text-sm text-slate-400 italic -mt-6">
                ({urlWidth}' x {urlLength}' Rink Shown)
              </div>
            </div>
          )}

          {modalIndex == 6 && (
            <div className="flex flex-col items-center justify-center">
              <h5 className="text-center mb-4 italic text-ezice-blue-600">
                Please enter the details of your existing rink below
              </h5>

              <h4 className="text-center bk5:text-base font-bold text-ezice-blue-600">
                Last question!
              </h4>
              <h4 className="text-center bk5:text-base mb-4 font-bold text-ezice-blue-600">
                Which style boards do you have?
              </h4>
              <div className="flex items-center justify-center mb-4">
                <button
                  className={classNames(
                    currentRink.type === "P"
                      ? "bg-ezice-blue-600"
                      : "bg-ezice-blue-100 hover:bg-ezice-blue-800",
                    "center mr-32 p-2 text-lg w-28 font-bold text-white rounded-full flex justify-center items-center"
                  )}
                  onClick={() => setCurrentRinkFields({ type: "P" })}
                >
                  PRO
                </button>
                <button
                  className={classNames(
                    currentRink.type === "O"
                      ? "bg-ezice-blue-600"
                      : "bg-ezice-blue-100 hover:bg-ezice-blue-800",
                    "center ml-32 p-2 text-lg w-28 font-bold text-white rounded-full flex justify-center items-center"
                  )}
                  onClick={() => setCurrentRinkFields({ type: "O" })}
                >
                  Original
                </button>
              </div>

              <div className="flex center">
                <img
                  className="w-[600px] center"
                  src="https://ezice.s3.amazonaws.com/upgrade-calc/img/pro_org.jpg"
                ></img>
              </div>

              <div className="flex items-center justify-center">
                <div className="italic text-sm text-ezice-blue-600 mr-2">
                  HINT: PRO Boards say "PRO" in the middle
                </div>
                <div className="italic text-sm text-ezice-blue-600 ml-2">
                  HINT: Original boards have two different ends
                </div>
              </div>
            </div>
          )}

          {modalIndex == 7 && (
            <div className="flex flex-col items-center justify-center">
              <h5 className="text-center mb-4 italic text-ezice-blue-600">
                Please enter the details of your existing rink below
              </h5>

              <h4 className="text-center bk5:text-base mb-4 font-bold text-ezice-blue-600">
                Which style boards do you have?
              </h4>
              <div className="flex items-center justify-center mb-4">
                <button
                  className={classNames(
                    currentRink.type === "P"
                      ? ""
                      : "bg-ezice-blue-100 hover:bg-ezice-blue-800",
                    "center mr-2 p-2 text-lg w-28 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                  )}
                  onClick={() => setCurrentRinkFields({ type: "P" })}
                >
                  PRO
                </button>
                <button
                  className={classNames(
                    currentRink.type === "O"
                      ? ""
                      : "bg-ezice-blue-100 hover:bg-ezice-blue-800",
                    "center ml-2 p-2 text-lg w-28 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                  )}
                  onClick={() => setCurrentRinkFields({ type: "O" })}
                >
                  Original
                </button>
              </div>

              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/_bYL3RB_vlo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>

              <div
                className="italic text-lg underline cursor-pointer text-ezice-blue-600 mt-2 mr-2"
                onClick={() => setModalIndex(10)}
              >
                Still not sure?
              </div>
            </div>
          )}

          {modalIndex == 8 && (
            <div className="flex flex-col items-center justify-center">
              <h4 className="text-center bk5:text-base mb-4 font-bold text-ezice-blue-600">
                Do you want to skip for now or contact EZ ICE?
              </h4>
              <div className="flex items-center justify-center mb-4">
                <button
                  className="hover:bg-ezice-blue-800 bk5:mt-0 bk5:text-sm bk5:w-24 bk5:p-0 mr-8 text-lg w-48 p-3 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                  onClick={goForward}
                >
                  Skip for now
                </button>
                <button className="hover:bg-ezice-blue-800 bk5:mt-0 bk5:text-sm bk5:w-24 bk5:p-0 ml-8 text-lg w-48 p-3 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center">
                  <a
                    className="text-white hover:no-underline"
                    href="mailto: expert@ezicerinks.com"
                  >
                    Contact EZ ICE
                  </a>
                </button>
              </div>
            </div>
          )}

          {modalIndex == 9 && (
            <div className="flex flex-col items-center justify-center">
              <h4 className="text-center bk5:text-base font-bold text-center text-ezice-blue-600">
                Please confirm.
              </h4>
              <h4 className="text-center bk5:text-base mb-4 font-bold text-center text-ezice-blue-600">
                Does this look like your existing rink?
              </h4>

              <h5 className="text-center font-bold text-ezice-blue-600 text-center mb-0">
                {generateRinkName(
                  currentRink.width < currentRink.length
                    ? currentRink.width
                    : currentRink.length,
                  currentRink.length > currentRink.width
                    ? currentRink.length
                    : currentRink.width,
                  currentRink.left,
                  currentRink.middle,
                  currentRink.right,
                  currentRink.corners,
                  currentRink.bumpers
                )}
              </h5>

              <img className="w-[400px]" src={currentRinkUrl} />
              <div className="text-sm text-slate-400 italic -mt-6">
                ({urlWidth}' x {urlLength}' Rink Shown)
              </div>

              <div className="flex items-center justify-center mt-4">
                <button
                  className="hover:bg-ezice-blue-800 bk5:mt-0 bk5:text-sm bk5:w-24 bk5:p-0 mr-2 text-lg w-36 p-2 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                  onClick={() => goToRink()}
                >
                  {loading ? <ClipLoader color="#ffffff" size={25} /> : "Yes"}
                </button>
                <button
                  className={classNames(
                    loading
                      ? "bg-ezice-blue-100 hover:cursor-default"
                      : "bg-ezice-blue-600 hover:bg-ezice-blue-800",
                    "ml-2 text-lg w-36 p-2 bk5:mt-0 bk5:text-sm bk5:w-24 bk5:p-0 font-bold text-white rounded-full flex justify-center items-center"
                  )}
                  onClick={() => !loading && goForward()}
                >
                  Not Sure
                </button>
              </div>
            </div>
          )}

          {modalIndex == 10 && (
            <div className="flex flex-col items-center justify-center">
              <h4 className="text-center bk5:text-base mb-4 font-bold text-ezice-blue-600">
                Do you want to go back or contact EZ ICE?
              </h4>
              <div className="flex items-center justify-center mb-4">
                <button
                  className="hover:bg-ezice-blue-800 bk5:mt-0 bk5:text-sm bk5:w-24 bk5:p-0 mr-8 text-lg w-48 p-3 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                  onClick={() => setModalIndex(2)}
                >
                  Edit Rink
                </button>
                <button className="hover:bg-ezice-blue-800 bk5:mt-0 bk5:text-sm bk5:w-36 bk5:p-0 ml-8 text-lg w-48 p-3 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center">
                  <a
                    className="text-white hover:no-underline"
                    href="mailto: expert@ezicerinks.com"
                  >
                    Contact EZ ICE
                  </a>
                </button>
              </div>
            </div>
          )}

          {modalIndex == 11 && (
            <Calc
              loading={loading}
              upgradePrice={upgradePrice}
              upgradeRinkName={generateRinkName(
                upgradeRink.width < upgradeRink.length
                  ? upgradeRink.width
                  : upgradeRink.length,
                upgradeRink.length > upgradeRink.width
                  ? upgradeRink.length
                  : upgradeRink.width,
                upgradeRink.left,
                upgradeRink.middle,
                upgradeRink.right,
                upgradeRink.corners,
                upgradeRink.bumpers
              )}
              upgradeRink={upgradeRink}
              currentRink={currentRink}
              calculateUpgradePrice={(rink) => calculateUpgradePrice(rink)}
              addToCart={() => addToCart()}
            />
          )}

          {modalIndex == 12 && (
            <div className="flex flex-col items-center">
              <h4 className="text-center bk5:text-base font-bold text-ezice-blue-600">
                Your EZ Upgrade looks great!
              </h4>
              <h4 className="text-center bk5:text-base mb-4 text-center font-bold text-ezice-blue-600">
                Now tell us a bit about your existing rink.
              </h4>

              <button
                className="hover:bg-ezice-blue-800 bk5:mt-0 bk5:text-sm bk5:w-24 bk5:p-0 mt-4 w-28 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                onClick={() => setModalIndex(3)}
              >
                Next
                <ArrowSmRightIcon
                  className="h-12 w-8 text-white"
                  aria-hidden="true"
                />
              </button>
            </div>
          )}

          {modalIndex == 13 && (
            <div className="flex flex-col items-center justify-center">
              <h4 className="text-center bk5:text-base text-center font-bold text-ezice-blue-600">
                Please confirm.
              </h4>
              <h4 className="text-center bk5:text-base text-center  mb-4 font-bold text-ezice-blue-600">
                How does your new rink look?
              </h4>

              <h5 className="text-center mb-4 font-bold text-ezice-blue-600 w-[600px] text-center">
                {generateRinkName(
                  upgradeRink.width < upgradeRink.length
                    ? upgradeRink.width
                    : upgradeRink.length,
                  upgradeRink.length > upgradeRink.width
                    ? upgradeRink.length
                    : upgradeRink.width,
                  upgradeRink.left,
                  upgradeRink.middle,
                  upgradeRink.right,
                  upgradeRink.corners,
                  upgradeRink.bumpers
                )}
              </h5>

              <h4 className="text-center bk5:text-base font-bold mb-0">
                Price: ${numberWithCommas(upgradePrice)}
              </h4>

              <img className="w-[400px]" src={upgradeRinkUrl} />

              <div className="flex items-center justify-center mt-4">
                <button
                  className="hover:bg-ezice-blue-800 bk5:mt-0 bk5:text-sm bk5:w-24 bk5:p-0 mr-2 text-lg w-36 p-2 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                  onClick={addToCart}
                >
                  {loading ? (
                    <ClipLoader color="#ffffff" size={25} />
                  ) : (
                    "Add to Cart"
                  )}
                </button>
                <button
                  className="hover:bg-ezice-blue-800 bk5:mt-0 bk5:text-sm bk5:w-24 bk5:p-0 ml-2 text-lg w-36 p-2 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                  onClick={() => setModalIndex(1)}
                >
                  Make Changes
                </button>
              </div>
            </div>
          )}

          {modalIndex == 14 && (
            <div className="flex flex-col items-center justify-center">
              <h4 className="text-center bk5:text-base font-bold text-ezice-blue-600">
                Last step!
              </h4>
              <h4 className="text-center bk5:text-base mb-4 font-bold text-ezice-blue-600">
                Do you need a liner?
              </h4>

              <div className="flex flex-col items-center">
                <h4 className="text-center bk5:text-base w-96 text-lg text-center">
                  <b>{liner.title}</b>
                </h4>
                <h4 className="text-center bk5:text-base font-bold mb-0 text-center">
                  Price: ${numberWithCommas(liner.price)}
                </h4>

                <img
                  className="w-48"
                  src="https://ezice.s3.amazonaws.com/upgrade-calc/img/liner.png"
                />
              </div>

              <div className="flex items-center justify-center mt-4">
                <button
                  className="hover:bg-ezice-blue-800 bk5:mt-0 bk5:text-sm bk5:w-24 bk5:p-0 mr-2 text-lg w-48 p-2 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                  onClick={() => checkout(true)}
                >
                  {loading ? <ClipLoader color="#ffffff" size={25} /> : "Yes"}
                </button>
                <button
                  className="hover:bg-ezice-blue-800 bk5:mt-0 bk5:text-sm bk5:w-24 bk5:p-0 ml-2 text-lg w-48 p-2 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center"
                  onClick={() => checkout(false)}
                >
                  {loading ? (
                    <ClipLoader color="#ffffff" size={25} />
                  ) : (
                    "Not at this time"
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="flex">
          {shouldRenderBack() && (
            <button
              className="mt-2 w-36 bk5:mt-0 bk5:text-sm bk5:w-24 bk5:p-0 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center hover:bg-ezice-blue-800"
              onClick={goBack}
            >
              <ArrowSmLeftIcon
                className="h-12 w-8 text-white"
                aria-hidden="true"
              />
              Back
            </button>
          )}

          {modalIndex == 6 && (
            <button
              className="ml-auto p-2 mt-2 w-36 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center hover:bg-ezice-blue-800"
              onClick={() => setModalIndex(7)}
            >
              Not Sure
            </button>
          )}

          {shouldRenderForward() && (
            <button
              className="bk5:mt-0 bk5:text-sm bk5:w-24 bk5:p-0 ml-auto mt-2 w-36 font-bold text-white bg-ezice-blue-600 rounded-full flex justify-center items-center hover:bg-ezice-blue-800"
              onClick={goForward}
            >
              Next
              <ArrowSmRightIcon
                className="h-12 w-8 text-white"
                aria-hidden="true"
              />
            </button>
          )}
        </div>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

function insertQueryParam(key, value) {
  if (window.history.pushState) {
    let searchParams = new URLSearchParams(window.location.search);
    let nav = window.location.href.split("#")[1];
    searchParams.set(key, value);

    let newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      searchParams.toString() +
      (nav == "undefined" || !nav ? "" : `#${nav}`);
    window.history.pushState({ path: newurl }, "", newurl);
  }
}
function getQueryParam(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
